import {
  FoBannerControllerService,
  FoBannerVo,
} from '@/__generated__/FrontApi';
import iconFacebook from '@/assets/img/join/ico_facebook@2x.png';
import iconGoogle from '@/assets/img/join/ico_google@2x.png';
import iconNaver from '@/assets/img/join/ico_naver@2x.png';
import Button from '@/components/Button';
import CampaignCard, { CampaignData } from '@/components/Card/CampaignCard';
import ErrorMessage from '@/components/Form/ErrorMessage';
import FormGroup from '@/components/Form/FormGroup';
import Col from '@/components/Grid/Col';
import Row from '@/components/Grid/Row';
import HR from '@/components/HR';
import InputCheckbox from '@/components/Input/InputCheckbox';
import InputPassword from '@/components/Input/InputPassword';
import InputText from '@/components/Input/InputText';
import { Tit } from '@/components/Titles';
import { breakpoint } from '@/helpers/BreakpointHelper';
import { isBrowser } from '@/helpers/BrowserHelper';
import LayoutWithoutTitle from '@/layouts/LayoutWithoutTitle';
import {
  FormContainer,
  PageTitle,
  SocialButton,
  SocialButtonText,
} from '@/page-blocks/auth/AuthCommon';
import LocalStorage from '@/services/LocalStorage';
import { usePopupStore } from '@/stores/PopupStore';
import { useUserStore } from '@/stores/UserStore';
import { useGoogleLogin } from '@react-oauth/google';
import { Link, navigate, PageProps } from 'gatsby';
import { observer } from 'mobx-react-lite';
import queryString from 'query-string';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { ReactFacebookLoginInfo } from 'react-facebook-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { SubmitHandler, useForm } from 'react-hook-form';
import styled from 'styled-components';

const NAVER_ID_SDK_URL = `https://static.nid.naver.com/js/naveridlogin_js_sdk_2.0.2.js`;

const LoginPageBanners = styled.div`
  padding-top: 50px;

  .bn-slide {
    .swiper-slide {
      width: 100%;

      .img-box {
        padding-top: 19.64%;
      }
    }
  }
`;

interface LoginFormData {
  loginId: string;
  loginPw: string;
  isSavingId: boolean;
}

const Login: FC<PageProps> = observer((props) => {
  const { location } = props;
  const prevPath = location?.state?.prevPath ?? ``;
  const { redirectTo } = queryString.parse(location.search);
  const hashStr = location?.hash == null ? `` : location?.hash;
  // const { access_token: accessToken } = queryString.parse(hashStr);
  const userStore = useUserStore();
  const popupStore = usePopupStore();

  const naverRef = useRef();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginFormData>({
    defaultValues: {
      loginId: LocalStorage.getItem(`savedLoginId`) || ``,
      isSavingId: !!LocalStorage.getItem(`savedLoginId`),
    },
  });

  const navigateNext = useCallback(() => {
    // redirectTo url을 받아와서 로그인후 넘어가기, default는 홈
    navigate(
      (((redirectTo == null || redirectTo === undefined
        ? prevPath
        : redirectTo) + hashStr) as string) || `/`,
      {
        state: {
          // 개인정보수정 등 재인증이 필요한 구간에서 인증 skip하기 위함
          skipAuth: true,
        },
      },
    );
  }, [hashStr, redirectTo, prevPath]);

  const onNaverLoginButtonClick = () => {
    naverRef.current?.children[0].click();
  };

  const loginWithNaver = useCallback(
    async (accessToken) => {
      try {
        const error = await userStore.loginSocial(
          `naver`,
          accessToken as string,
        );
        if (error && error.socialId) {
          popupStore.show(error.returnMessage, {
            onConfirm: () => navigate(`/auth/join`),
          });
        }
        // navigateNext();
        navigate('/');
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
      }
    },
    [popupStore, userStore],
  );

  useEffect(() => {
    if (!isBrowser) {
      return;
    }

    const initNaverLoginButton = () => {
      const { naver } = window;
      if (!naver) {
        return;
      }
      const naverLogin = new naver.LoginWithNaverId({
        callbackUrl: `${process.env.SELF_URL}/auth/login`,
        clientId: process.env.NAVER_LOGIN_CLIENT_ID,
        isPopup: false,
        loginButton: { color: `green`, type: 1, height: 60 },
        callbackHandle: true,
      });
      naverLogin.init();
      naverLogin.logout();

      naverLogin.getLoginStatus((status: any) => {
        if (status) {
          // 필수로 받아야 하는 정보가 있다면 reprompt 실행
          const email = naverLogin.user.getEmail();
          if (!email) {
            // eslint-disable-next-line no-alert
            alert(`이메일은 필수입니다. 정보제공에 동의해주세요.`);
            naverLogin.reprompt();
          }
          if (naverLogin.accessToken && naverLogin.accessToken.accessToken) {
            loginWithNaver(naverLogin.accessToken.accessToken);
          }
        }
      });
    };

    const loadScript = () => {
      if (document.querySelectorAll(`#naver-login-sdk`).length === 0) {
        const script = document.createElement(`script`);
        script.id = `naver-login-sdk`;
        script.src = NAVER_ID_SDK_URL;
        script.onload = () => initNaverLoginButton();
        document.head.appendChild(script);
      } else {
        // script가 이미 로드된 경우, init만 실행
        initNaverLoginButton();
      }
    };
    loadScript();
    // initNaverLoginButton();
  }, [loginWithNaver]);

  // useEffect(() => {
  //   const loginWithNaver = async () => {
  //     try {
  //       const error = await userStore.loginSocial(
  //         `naver`,
  //         accessToken as string,
  //       );
  //       if (error && error.socialId) {
  //         popupStore.show(error.returnMessage, {
  //           onConfirm: () => navigate(`/auth/join`),
  //         });
  //       }
  //       // navigateNext();
  //       navigate('/');
  //     } catch (e) {
  //       // eslint-disable-next-line no-console
  //       console.log(e);
  //     }
  //   };

  //   if (accessToken) {
  //     loginWithNaver();
  //   }
  // }, [accessToken, popupStore, userStore]);

  // 일반 로그인 (폼 submit시)
  const onSubmit: SubmitHandler<LoginFormData> = async ({
    loginId,
    loginPw,
    isSavingId,
  }) => {
    // 아이디 저장
    if (isSavingId) {
      LocalStorage.setItem(`savedLoginId`, loginId.trim());
    } else {
      LocalStorage.removeItem(`savedLoginId`);
    }

    const error = await userStore.login({ loginId, loginPw });
    if (error) {
      popupStore.show(error);
    }
  };

  // 페이스북 로그인
  const loginWithFacebook = useCallback(
    async ({ accessToken }: ReactFacebookLoginInfo) => {
      const error = await userStore.loginSocial(`facebook`, accessToken);
      if (error && error.socialId) {
        popupStore.show(error.returnMessage, {
          onConfirm: () => navigate(`/auth/join`),
        });
      }
    },
    [popupStore, userStore],
  );

  // 구글 로그인
  const loginWithGoogle = useGoogleLogin({
    onSuccess: async (res) => {
      const error = await userStore.loginSocial(`google`, res.code);
      if (error && error.socialId) {
        popupStore.show(error.returnMessage, {
          onConfirm: () => navigate(`/auth/join`),
        });
      }
    },
    // eslint-disable-next-line no-console
    onError: (e) => console.error(e),
    flow: 'auth-code',
  });

  const googleLoginClick = () => {
    if (window.navigator.userAgent.match('KAKAOTALK')) {
      alert(
        `KAKAOTALK 앱 브라우저에서는 구글 간편 로그인이 지원되지 않습니다. 다른 수단 또는 외부 브라우저를 이용해 주세요.`,
      );
    } else if (window.navigator.userAgent.match('NAVER')) {
      alert(
        `NAVER 앱 브라우저에서는 구글 간편 로그인이 지원되지 않습니다. 다른 수단 또는 외부 브라우저를 이용해 주세요.`,
      );
    } else {
      loginWithGoogle();
    }
  };

  useEffect(() => {
    // 로그인 되었으면 이동
    if (userStore.user) {
      navigateNext();
      // navigate(`/`);
    }
  }, [navigateNext, userStore.user]);

  const [banners, setBanners] = useState<CampaignData[]>([]);

  const loadBanners = useCallback(async () => {
    try {
      const {
        data: loginPageBanners,
      } = await FoBannerControllerService.selectBannerListUsingGet({
        kind: '6001',
      });
      setBanners(
        loginPageBanners.map((banner: FoBannerVo) => ({
          id: banner.indexNumber,
          pcImage: banner.imgAttGrpNoPc,
          mobileImage: banner.imgAttGrpNoMobile,
          link: banner.targetUrl,
          linkTargetBlank: banner.targetBlankYn === `Y`,
        })),
      );
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    loadBanners();
  }, [loadBanners]);

  return (
    <LayoutWithoutTitle location={location}>
      {/* <Helmet>
        <script type="text/javascript" src={NAVER_ID_SDK_URL} />
      </Helmet> */}
      <FormContainer>
        <PageTitle>
          <h2>
            <Tit size="s1-5" color="sky" weight="normal">
              유니세프한국위원회를 <br />
              방문해주셔서 감사합니다
            </Tit>
          </h2>
          <p>로그인을 통해, 나의 후원정보를 확인해보세요.</p>
        </PageTitle>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormGroup>
            <Row>
              <InputText
                name="loginId"
                ref={register({
                  required: {
                    value: true,
                    message: `필수 입력입니다`,
                  },
                })}
                placeholder="아이디"
              />
            </Row>
            {errors.loginId && (
              <ErrorMessage>{errors.loginId.message}</ErrorMessage>
            )}
            {` `}
            <Row
              css={`
                margin-top: 16px;
              `}
            >
              <InputPassword
                name="loginPw"
                ref={register({
                  required: {
                    value: true,
                    message: `필수 입력입니다`,
                  },
                })}
                placeholder="비밀번호"
              />
            </Row>
            {errors.loginPw && (
              <ErrorMessage>{errors.loginPw.message}</ErrorMessage>
            )}
            <Row
              css={`
                margin-top: 12px;
              `}
            >
              <InputCheckbox
                label="아이디저장"
                name="isSavingId"
                ref={register}
              />
              <Link to="/auth/find-id" className="gray-underline">
                아이디/비밀번호 찾기
              </Link>
            </Row>
          </FormGroup>
          <Button type="submit" full>
            로그인
          </Button>

          <FormGroup
            css={`
              margin-top: 38px;
            `}
          >
            <Row>회원이 아니시라면, 지금 회원에 가입하세요.</Row>
            <Row
              css={`
                margin-top: 14px;
              `}
            >
              <Button
                type="button"
                full
                outline
                onClick={() => navigate(`/auth/join`)}
              >
                회원가입
              </Button>
            </Row>
          </FormGroup>
          <HR
            css={`
              margin: 40px 0;
            `}
          />
          <h3>
            <Tit
              size="s4"
              css={`
                line-height: 1.6;
                letter-spacing: -1.2px;
                ${breakpoint(`mobile`)} {
                  letter-spacing: -0.8px;
                }
              `}
            >
              SNS 계정으로 간편 로그인 하기
            </Tit>
          </h3>
          <Row
            css={`
              margin-top: 30px;
            `}
          >
            <Col align="center">
              <SocialButton id="" onClick={onNaverLoginButtonClick}>
                <img src={iconNaver} alt="네이버" width="60" height="60" />
                <br />
                <SocialButtonText>네이버</SocialButtonText>
              </SocialButton>
              <div
                id="naverIdLogin"
                ref={naverRef}
                style={{ display: 'none' }}
              />
            </Col>
            <Col align="center">
              <FacebookLogin
                appId={process.env.FACEBOOK_LOGIN_APP_ID || ``}
                fields="name,email"
                disableMobileRedirect
                render={({ onClick }) => (
                  <SocialButton onClick={onClick}>
                    <img
                      src={iconFacebook}
                      alt="페이스북"
                      width="60"
                      height="60"
                    />
                    <br />
                    <SocialButtonText>페이스북</SocialButtonText>
                  </SocialButton>
                )}
                callback={loginWithFacebook}
              />
            </Col>
            <Col align="center">
              <SocialButton onClick={() => googleLoginClick()}>
                <img src={iconGoogle} alt="구글" width="60" height="60" />
                <br />
                <SocialButtonText>구글</SocialButtonText>
              </SocialButton>
            </Col>
          </Row>
        </form>

        <LoginPageBanners>
          {banners.length > 0 && (
            <CampaignCard
              campaigns={banners}
              autoplay
              overflowHidden
              className="bn-slide"
              img
            />
          )}
        </LoginPageBanners>
      </FormContainer>
    </LayoutWithoutTitle>
  );
});

export default Login;
